<template>
  <div>
    <b-overlay :show="isLoadingData" rounded="sm">
      <b-row>
        <b-col cols="12">
          <b-card class="border-0">
            <b-card-body class="scrollFlow">
              <b-navbar toggleable="lg" type="dark" variant="light">
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-navbar-brand
                  href="#"
                  class="text-dark"
                  data-test-id="associates-tab__table-title"
                >
                  <strong>{{ $t("eb360.associateTabComponent.title") }}</strong>
                </b-navbar-brand>

                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                      <!-- search bar start -->
                      <b-form-group>
                        <b-input-group
                          size="sm"
                          data-test-id="associates-tab__table-search"
                        >
                          <b-form-input
                            v-model="filter"
                            type="search"
                            id="filterInput"
                            :placeholder="
                              $t('eb360.manageThirdParties.searchPlaceholder')
                            "
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button
                              :disabled="!filter"
                              @click="filter = ''"
                              >{{ $t("clearBtnText") }}</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <!-- search bar end -->
                    </b-nav-form>
                  </b-navbar-nav>
                </b-collapse>
              </b-navbar>

              <!-- Associates table data start -->
              <b-table
                bordered
                sticky-header="calc(100vh - 250px)"
                ref="table"
                id="my-table"
                :fields="fields"
                :items="associatesTableData"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="sm"
                small
                outlined
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                data-test-id="associates-tab__table"
              >
                <template v-slot:cell(srch_type)="data">
                  <div
                    class="text-center"
                    :data-test-id="`associates-tab_icon--${
                      data.item.srch_type === thirdPartyType.ORGANIZATION
                        ? 'organisation'
                        : 'individual'
                    }`"
                  >
                    <font-awesome-icon
                      :icon="
                        data.item.srch_type === thirdPartyType.ORGANIZATION
                          ? 'building'
                          : 'male'
                      "
                      class="mr-2"
                    />
                  </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div class="text-center">
                    <IconWithText
                      v-bind="getAssociatesIcon(data.item.status)"
                    />
                  </div>
                </template>
              </b-table>
              <!-- Associates table data end -->
              <b-row>
                <b-col cols="2">
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    aria-controls="my-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- Modal for create associate start-->
    <!-- Currently not in use -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :title="$t('eb360.associateTabComponent.createAssociates')"
      scrollable
      v-model="showModal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label>
                  {{ $t("eb360.associateTabComponent.associateName") }}:
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  size="sm"
                  v-model="v$.form.associatesName.$model"
                  :state="
                    v$.form.associatesName.$dirty
                      ? !v$.form.associatesName.$error
                      : null
                  "
                  :placeholder="$t('eb360.associateTabComponent.enterName')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label>
                  {{ $t("eb360.associateTabComponent.associateType") }}:
                  <span class="text-danger">*</span>
                </label>
                <b-form-select
                  v-model="v$.form.associatesType.$model"
                  :state="
                    v$.form.associatesType.$dirty
                      ? !v$.form.associatesType.$error
                      : null
                  "
                  class="w-100"
                  size="sm"
                >
                  <b-form-select-option :value="null">{{
                    $t("eb360.associateTabComponent.associateSelect")
                  }}</b-form-select-option>
                  <b-form-select-option value="O">{{
                    $t("eb360.associateDetailModal.organisation")
                  }}</b-form-select-option>
                  <b-form-select-option value="P">{{
                    $t("eb360.associateDetailModal.individual")
                  }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label>
                  {{ $t("eb360.associateTabComponent.associateCountry") }}:
                  <span class="text-danger">*</span>
                </label>
                <b-form-select
                  label="name"
                  v-model="v$.form.associatesCountry.$model"
                  :state="
                    v$.form && v$.form.associatesCountry.$dirty
                      ? !v$.form.associatesCountry.$error
                      : null
                  "
                  :options="countries"
                  :class="
                    v$.form.associatesCountry.$error
                      ? 'border border-danger rounded form-control-small'
                      : 'form-control-small'
                  "
                  @input="setCountryValue"
                >
                  <template slot="option" slot-scope="option">
                    <country-flag
                      v-if="option.icon"
                      :country="option.icon"
                      size="small"
                    />
                    <span>{{ "&nbsp;" + option.name }}</span>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="form.associatesType == 'P'">
              <b-form-group>
                <label
                  >{{
                    $t("eb360.associateTabComponent.associatePosition")
                  }}:</label
                >
                <b-form-input
                  size="sm"
                  v-model="form.associatesPosition"
                  :placeholder="$t('eb360.associateTabComponent.enterPosition')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :class="
                  form.associatesType == 'P' ? '' : 'group-radio-condition'
                "
              >
                <b-form-radio-group
                  v-model="form.reportType"
                  class="w-100"
                  size="sm"
                >
                  <b-form-radio value="require_idd">{{
                    $t("eb360.associateTabComponent.iddRequire")
                  }}</b-form-radio>
                  <b-form-radio value="require_idd_plus">{{
                    $t("eb360.associateTabComponent.iddPlusRequire")
                  }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <Checkbox
                  v-model="form.enableOgm"
                  class="w-100"
                  checked-value="ogm_enabled"
                  unchecked-value=""
                  :label="$t('eb360.associateTabComponent.enableOgm')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mb-4 text-center">
            <b-btn
              @click="checkValidation"
              variant="outline-success"
              size="sm"
              >{{
                isEditAssociatesData ? $t("updateBtn") : $t("addBtn")
              }}</b-btn
            >
            <b-btn @click="resetForm" variant="outline-info" size="sm">{{
              $t("resetBtn")
            }}</b-btn>
          </div>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>{{
                  $t("eb360.associateTabComponent.associateName")
                }}</b-th>
                <b-th>{{
                  $t("eb360.associateTabComponent.associateType")
                }}</b-th>
                <b-th>{{
                  $t("eb360.associateTabComponent.associateCountry")
                }}</b-th>
                <b-th v-if="form.associatesType == 'P'">{{
                  $t("eb360.associateTabComponent.associatePosition")
                }}</b-th>
                <b-th>{{
                  $t("eb360.associateTabComponent.associateReport")
                }}</b-th>
                <b-th>{{ $t("eb360.associateTabComponent.ogm") }}</b-th>
                <b-th>{{ $t("eb360.associateTabComponent.actions") }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(items, index) in associatesData" :key="index">
                <b-td>{{ items.associateName }}</b-td>
                <b-td>{{
                  items.associateType == "O"
                    ? $t("eb360.associateDetailModal.organisation")
                    : $t("eb360.associateDetailModal.individual")
                }}</b-td>
                <b-td>{{ items.associateCountry }}</b-td>
                <b-td v-if="form.associatesType == 'P'">{{
                  items.associatePosition
                }}</b-td>
                <b-td>{{
                  items.reportType == null
                    ? "N/A"
                    : items.reportType == "require_idd"
                    ? $t("eb360.associateTabComponent.iddRequire")
                    : $t("eb360.associateTabComponent.iddPlusRequire")
                }}</b-td>
                <b-td>{{
                  items.enableOgm == "ogm_enabled"
                    ? $t("eb360.manageThirdParties.yes")
                    : $t("eb360.manageThirdParties.no")
                }}</b-td>
                <b-td>
                  <b-btn
                    size="sm"
                    variant="outline-warning"
                    @click="editAssociatedData(items, index)"
                  >
                    <font-awesome-icon icon="edit" />
                  </b-btn>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <p>{{ $t("eb360.associateTabComponent.creatingAssociates") }}</p>
          <b-spinner type="grow" :label="$t('loadingSpinnerText')"></b-spinner>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{ $t("eb360.associateTabComponent.successfullAssociates") }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          size="md"
          v-if="!submiting"
          variant="outline-danger"
          @click="resetModal"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          size="md"
          v-if="!submiting && !responseSuccess"
          :disabled="associatesData.length > 0 ? false : true"
          variant="outline-success"
          @click="calSaveAssociates()"
          >{{ $t("saveBtnText") }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from "moment";
import "moment/locale/es";
import IconData from "@/utils/icon.json";
import {
  riskIcons,
  thirdPartyType,
  thirdPartyAssociatesRisk
} from "@/constants";
import IconWithText from "@/molecules/IconWithText/IconWithText";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "AssociatesTabComponent",
  components: {
    IconWithText,
    Checkbox
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      iconJson: IconData,
      selectedAssociatesData: null,
      associatesData: [],
      isEditAssociatesData: false,
      form: {
        associatesName: null,
        associatesType: null,
        associatesCountry: null,
        associatesPosition: null,
        reportType: null,
        enableOgm: null
      },
      primaryContactsId: null,
      countryValue: null,
      submiting: false,
      responseSuccess: false,
      showModal: false,
      isLoadingData: false,
      filter: null,
      pageOptions: [5, 10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      primaryContactsItems: [],
      fields: [
        {
          key: "asso_name",
          label: this.$t("eb360.addThirdParty.name"),
          sortable: true
        },
        {
          key: "srch_type",
          label: this.$t("eb360.manageThirdParties.entityType"),
          sortable: true
        },
        {
          key: "asso_count",
          label: this.$t("eb360.associateDetailModal.country"),
          sortable: true
        },
        {
          key: "created_date",
          label: this.$t("eb360.associateTabComponent.createdAt"),
          sortable: true
        },
        {
          key: "status",
          label: this.$t("eb360.reviewResult.status"),
          sortable: false
        }
      ],
      associatesTableData: [],
      thirdPartyType
    };
  },
  validations: {
    form: {
      associatesName: { required },
      associatesType: { required },
      associatesCountry: { required }
    }
  },
  computed: {
    ...mapState({
      countries: (state) => state.country.countries,
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.totalRows.length;
    }
  },
  watch: {
    thirdPartyData: {
      deep: true,
      handler() {
        this.setTableData();
      }
    }
  },
  mounted() {
    this.setTableData();
  },
  methods: {
    setTableData() {
      this.associatesTableData = this.thirdPartyData?.latest_assoc_risk || [];
      this.$store.state.associatesTabLazyLoad = false;
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "my-table");
    },
    customFormatter(date) {
      moment.locale("en");
      return moment(date).format("MMMM DD YYYY");
    },
    setCountryValue(value) {
      this.countryValue = value.name;
    },
    resetForm() {
      this.form = {
        associatesName: null,
        associatesType: null,
        associatesCountry: null,
        associatesPosition: null,
        reportType: null,
        enableOgm: null
      };
      this.isEditAssociatesData = false;
      this.v$.$reset();
    },
    resetModal() {
      this.resetForm();
      this.associatesData = [];
      this.showModal = false;
      this.submiting = false;
      this.responseSuccess = false;
      this.primaryContactsId = null;
      this.countryValue = null;
    },
    // check validation before creating and updating associates
    checkValidation() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else if (this.isEditAssociatesData) {
        this.updateAssociatesData();
      } else {
        this.addAssociatesData();
      }
    },

    // create associate
    addAssociatesData() {
      let values = {
        associateName: this.form.associatesName,
        associateType: this.form.associatesType,
        associateCountry: this.countryValue,
        associatePosition: this.form.associatesPosition,
        reportType: this.form.reportType,
        enableOgm: this.form.enableOgm,
        productType: 1
      };
      this.associatesData.push(values);
      this.resetForm();
    },

    // open edit associate modal
    editAssociatedData(data, index) {
      this.isEditAssociatesData = true;
      this.selectedAssociatesData = index;
      this.form = {
        associatesName: data.associateName,
        associatesType: data.associateType,
        associatesCountry: data.associateCountry,
        associatesPosition: data.associatePosition,
        reportType: data.reportType,
        enableOgm: data.enableOgm
      };
    },

    // update associate
    updateAssociatesData() {
      this.associatesData[this.selectedAssociatesData].associateName =
        this.form.associatesName;
      this.associatesData[this.selectedAssociatesData].associateType =
        this.form.associatesType;
      this.associatesData[this.selectedAssociatesData].associateCountry =
        this.countryValue;
      this.associatesData[this.selectedAssociatesData].associatePosition =
        this.form.associatesPosition;
      this.associatesData[this.selectedAssociatesData].reportType =
        this.form.reportType;
      this.associatesData[this.selectedAssociatesData].enableOgm =
        this.form.associatesName;
      this.associatesData[this.selectedAssociatesData].productType = 1;
      this.resetForm();
    },
    calSaveAssociates() {
      this.saveAssociates().then(this.reloadTable);
    },
    async saveAssociates() {
      let formData = {
        associates: this.associatesData,
        thirdPartyId: this.thirdPartyId,
        companyId: this.companyId,
        memberId: this.userId
      };
      try {
        this.submiting = true;
        this.isLoadingData = true;
        await HTTP(
          "post",
          "thirdparty/add/associate",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoadingData = false;
        this.responseSuccess = true;
      } catch (err) {
        this.submiting = false;
        this.isLoadingData = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    getAssociatesIcon(status) {
      switch (status) {
        case thirdPartyAssociatesRisk.IN_PROGRESS:
          return riskIcons.IN_PROGRESS;
        case thirdPartyAssociatesRisk.RISK:
          return riskIcons.RISK_ALERT;
        case thirdPartyAssociatesRisk.NO_RISK:
          return riskIcons.NO_RISK_ALERT;
        case thirdPartyAssociatesRisk.NOT_RUN:
          return riskIcons.NOT_RUN;
        default:
          return riskIcons.NOT_AVAILABLE;
      }
    }
  },
  props: {
    companyId: Number,
    thirdPartyId: Number,
    search20Id: Number,
    isDisabled: Boolean,
    thirdPartyData: Object
  }
};
</script>
<style>
.group-radio-condition {
  margin-top: 35px;
}
.form-control-small .vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
