const actionName = {
  PLATFORM_SETTINGS: {
    FETCH_PUBLIC_PLATFORM_SETTINGS:
      "platformSettings/fetchPublicPlatformSettings",
    FETCH_PLATFORM_SETTINGS: "platformSettings/fetchPlatformSettings",
    UPDATE_PLATFORM_SETTINGS: "platformSettings/updatePlatformSettings",
    FETCH_PLATFORM_USER_LIST: "platformSettings/fetchPlatformUserList",
    FETCH_PRODUCT_VERSIONS: "platformSettings/fetchProductVersions"
  },
  AUTH: {
    LOG_IN: "auth/logIn",
    LOG_OUT: "auth/logOut",
    FETCH_EB360_APP_DATA: "auth/fetchEB360AppData"
  },
  USER_SETTINGS: {
    GET_USER_SETTINGS: "userSettings/getUserSettings"
  },
  THIRD_PARTY: {
    FETCH_T_CERTIFICATION_OPTIONS: "thirdParty/fetchTCertificationOptions",
    FETCH_ESG_METRICS_OPTIONS: "thirdParty/fetchESGMetricsOptions",
    FETCH_FORMS_LANGUAGES_LIST: "thirdParty/fetchFormsLanguagesList",
    FETCH_OC_STATES_LIST: "thirdParty/fetchOCStatesList",
    FETCH_HEADER_CONFIG_DATA: "thirdParty/fetchHeaderConfigData",
    FETCH_EDD_CLIENT_ALL_PACKAGES: "thirdParty/fetchEDDClientAllPackages",
    FETCH_CUSTOM_ATTRIBUTES: "thirdParty/fetchCustomAttributes",
    FETCH_ALL_THIRD_PARTY_DATA: "thirdParty/fetchAllThirdPartyData",
    FETCH_THIRD_PARTIES_UBOS: "thirdParty/fetchThirdPartyUBOs",
    CREATE_THIRD_PARTY_FROM_UBO: "thirdParty/createThirdPartyFromUBO",
    FETCH_THIRD_PARTY_TRAINING_LIST: "thirdParty/fetchThirdPartyTrainingList",
    FETCH_THIRD_PARTY_TRAINING_DETAILS:
      "thirdParty/fetchThirdPartyTrainingDetails",
    FETCH_THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE:
      "thirdParty/fetchThirdPartyBulkUploadSampleFile",
    SEND_EMAIL_FOR_THIRD_PARTY_UPDATE_EXCEL_SHEET:
      "thirdParty/sendEmailForThirdPartyUpdateExcelSheet"
  },
  ACTIONS: {
    FETCH_ACTIONS: "actions/fetchActions",
    FETCH_ACTION: "actions/fetchAction",
    SAVE_ACTION: "actions/saveAction",
    AMEND_ACTION: "actions/amendAction",
    DELETE_ACTION: "actions/deleteAction",
    CLONE_ACTION: "actions/cloneAction"
  },
  EXPECTED_DATA: {
    FETCH_EXPECTED_DATA: "expectedData/fetchExpectedData",
    FETCH_ENTITY_DYNAMIC_REFERENCE: "expectedData/fetchEntitiesDynamicReference"
  },
  ROLES: {
    FETCH_ROLES: "roles/fetchRoles",
    FETCH_ROLE: "roles/fetchRole",
    CREATED_ROLE: "roles/createRole",
    UPDATE_ROLE: "roles/updateRole",
    DELETE_ROLE: "roles/deleteRole"
  },
  COMPANY: {
    SELECT_COMPANY: "company/selectCompany",
    FETCH_COMPANIES: "company/fetchCompanies",
    FETCH_COMPANY_USERS: "company/fetchCompanyUsers",
    UPDATE_COMPANY_USER: "company/updateCompanyUser",
    ADD_COMPANY_USER: "company/addCompanyUser",
    DELETE_COMPANY_USER: "company/deleteCompanyUser",
    FETCH_ADDITIONAL_VOUCHERS: "company/fetchAdditionalVouchers",
    FETCH_COMPANY_FEATURES: "company/fetchCompanyFeatures",
    FETCH_CUSTOM_COMPANY_FEATURES: "company/fetchCustomCompanyFeatures",
    UPDATE_COMPANY_FEATURE: "company/updateCompanyFeature"
  },
  ESG: {
    FETCH_ESG_REPORTS: "esg/fetchESGReports",
    FETCH_ESG_REPORT_BY_ID: "esg/fetchESGReportById"
  },
  CUSTOM_ATTRIBUTES: {
    FETCH_CUSTOM_ATTRIBUTES_LIST: "customAttributes/fetchCustomAttributesList"
  },
  USER_EMAIL_TEMPLATES: {
    FETCH_USER_EMAIL_TEMPLATES: "userEmailTemplates/fetchUserEmailTemplates",
    FETCH_USER_EMAIL_TEMPLATE: "userEmailTemplates/fetchUserEmailTemplate",
    MAKE_USER_EMAIL_TEMPLATE: "userEmailTemplates/makeUserEmailTemplate",
    UPDATE_USER_EMAIL_TEMPLATE: "userEmailTemplates/updateUserEmailTemplate",
    CLONE_USER_EMAIL_TEMPLATE: "userEmailTemplates/cloneUserEmailTemplate",
    DELETE_USER_EMAIL_TEMPLATE: "userEmailTemplates/deleteUserEmailTemplate"
  },
  TASK_TEMPLATES: {
    FETCH_TASK_TEMPLATES: "taskTemplates/fetchTaskTemplates",
    FETCH_TASK_TEMPLATE: "taskTemplates/fetchTaskTemplate",
    MAKE_TASK_TEMPLATE: "taskTemplates/makeTaskTemplate",
    UPDATE_TASK_TEMPLATE: "taskTemplates/updateTaskTemplate",
    CLONE_TASK_TEMPLATE: "taskTemplates/cloneTaskTemplate",
    DELETE_TASK_TEMPLATE: "taskTemplates/deleteTaskTemplate"
  },
  UBO_ENTITY: {
    FETCH_UBO_ENTITY_DATA: "uboEntity/fetchUBOEntityData",
    FETCH_UBO_DUE_DILIGENCE_REPORT_DATA:
      "uboEntity/fetchUBODueDiligenceReportData"
  },
  ONBOARDING_TASKS: {
    FETCH_ONBOARDING_TASKS: "onboardingTasks/fetchOnboardingTasks",
    FETCH_ONBOARDING_TASK: "onboardingTasks/fetchOnboardingTask"
  },
  PERMISSIONS: {
    FETCH_FEATURE_PERMISSIONS_DATA: "permissions/fetchFeaturePermissionsData",
    UPDATE_FEATURE_PERMISSIONS_DATA: "permissions/updateFeaturePermissionsData",
    FETCH_USER_PERMISSIONS_DATA: "permissions/fetchUserPermissionsData",
    FETCH_USER_PERMISSIONS_SUBSET_DATA:
      "permissions/fetchUserPermissionsSubsetData"
  },
  NO_NAME_SPACE: {
    SET_FORM_DEFAULT_LANGUAGE: "setFormDefaultLanguage",
    SET_LANGUAGE: "setLanguage"
  },
  TASKS: {
    FETCH_TASKS: "tasks/fetchTasks",
    FETCH_TASK: "tasks/fetchTask",
    UPDATE_TASK: "tasks/updateTask",
    COMPLETE_TASK: "tasks/completeTask",
    REOPEN_TASK: "tasks/reopenTask",
    DELETE_TASK: "tasks/deleteTask"
  },
  USER_TRAINING: {
    ADD_NEW_TRAINING_LEARNER: "userTraining/addNewTrainingLearner",
    FETCH_USER_TRAINING_LIST: "userTraining/fetchUserTrainingList",
    REMOVE_TRAINING_LEARNER: "userTraining/removeTrainingLearner"
  },
  LANGUAGES: {
    FETCH_LANGUAGES: "languages/fetchLanguages"
  },
  PRODUCT: {
    FETCH_AVAILABLE_PRODUCT_LIST: "product/fetchAvailableProductList",
    FETCH_PLATFORM_PRODUCT_LIST: "product/fetchPlatformProductList",
    ASSIGN_AVAILABLE_PRODUCT_TO_PLATFORM:
      "product/assignAvailableProductToPlatform"
  }
};

export { actionName };
