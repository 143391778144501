import { productService } from "@/services";
import { mutationName, thirdPartyInfoMessages, actionName } from "@/constants";

export const actions = {
  async fetchPlatformProductList({ commit, rootState }) {
    const { tenantId } = rootState.onboardingClient.client;
    if (tenantId) {
      try {
        commit(mutationName.PRODUCT.SET_IS_LOADING_PRODUCT_LIST, true, {
          root: true
        });
        const { data = [] } = await productService.fetchPlatformProductList({
          tenantId
        });

        commit(mutationName.PRODUCT.SET_PLATFORM_PRODUCT_LIST, data, {
          root: true
        });

        if (!data?.length) {
          return thirdPartyInfoMessages.NO_TRAINING_LIST_FEEDBACK;
        }
      } finally {
        commit(mutationName.PRODUCT.SET_IS_LOADING_PRODUCT_LIST, false, {
          root: true
        });
      }
    } else {
      return thirdPartyInfoMessages.NO_TRAINING_LIST_FEEDBACK;
    }
  },
  async fetchAvailableProductList({ commit }) {
    const { data = [] } = await productService.fetchAvailableProductList();

    commit(mutationName.PRODUCT.SET_AVAILABLE_PRODUCT_LIST, data, {
      root: true
    });
  },
  async assignAvailableProductToPlatform(
    { dispatch, rootState },
    { courseIds }
  ) {
    const { tenantId } = rootState.onboardingClient.client;
    await productService.assignAvailableProductToPlatform({
      tenantId,
      courseIds
    });

    await dispatch(
      actionName.PRODUCT.FETCH_PLATFORM_PRODUCT_LIST,
      { tenantId },
      { root: true }
    );
  }
};
