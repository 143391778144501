export const state = () => ({
  tCertificationOrderTypes: null,
  ESGMetricsOrderTypes: null,
  formsLanguagesList: [],
  isThirdPartyAddModalVisible: false,
  isAddThirdPartyModalNewVisible: false,
  thirdPartyData: null,
  OCStatesList: [],
  headerConfigData: {},
  EDDClientAllPackages: [],
  customAttributes: [],
  isUBODataLoading: false,
  thirdPartyUBOList: [],
  thirdPartyUBOError: "",
  isCreatingThirdPartySuccessful: false,
  createThirdPartyError: false,
  isCreatingThirdParty: false,
  isBulkUploadModalVisible: false,
  thirdPartyTrainingList: [],
  isLoadingTrainingList: false,
  isLoadingThirdPartyTrainingDetails: false,
  thirdPartyTraining: {},
  isLoadingSendEmailForThirdPartyUpdateExcelSheet: false,
  isLoadingThirdPartyBulkUploadSampleFile: false,
  thirdPartyTrainingSummaryList: []
});
