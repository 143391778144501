import {
  customAttributesChartKeysAPIDTO,
  customAttributesChartKeysDTO,
  tasksChartKeysAPIDTO
} from "@/constants";

const makeCustomAttributeChartDataDTO = (data = []) =>
  data?.map((item) => ({
    [customAttributesChartKeysDTO.VALUE]:
      item?.[customAttributesChartKeysAPIDTO.VALUE],
    [customAttributesChartKeysDTO.COUNT]:
      item?.[customAttributesChartKeysAPIDTO.COUNT],
    [customAttributesChartKeysDTO.LABEL]:
      item?.[customAttributesChartKeysAPIDTO.LABEL]
  })) || [];

const makeTasksStatusWidgetDataDTO = (data = {}) => ({
  [tasksChartKeysAPIDTO.STATUSES]:
    data?.[tasksChartKeysAPIDTO.STATUSES]?.map?.((status) => ({
      [tasksChartKeysAPIDTO.STATUS]:
        status?.[tasksChartKeysAPIDTO.STATUS]?.toLowerCase?.(),
      [tasksChartKeysAPIDTO.COUNT]: status?.[tasksChartKeysAPIDTO.COUNT]
    })) || [],
  [tasksChartKeysAPIDTO.TOTAL]: data?.[tasksChartKeysAPIDTO.TOTAL] || 0
});

const makeTasksAgeWidgetDataDTO = (data = {}) =>
  data?.[tasksChartKeysAPIDTO.DATA]?.map?.((item) => ({
    [tasksChartKeysAPIDTO.VALUE]: item?.[tasksChartKeysAPIDTO.DATE],
    [tasksChartKeysAPIDTO.COUNT]: item?.[tasksChartKeysAPIDTO.COUNT]
  })) || [];

export {
  makeCustomAttributeChartDataDTO,
  makeTasksStatusWidgetDataDTO,
  makeTasksAgeWidgetDataDTO
};
