<template>
  <section class="product">
    <ProfileHeader
      heading="Assigned Training Courses"
      :CTAOptions="CTAOptions"
      @click="onCancel"
    />
    <form
      v-if="isAddNewProductVisible"
      class="product__form"
      data-test-id="product__form"
      @submit.prevent
    >
      <MultiSelect
        id="availableProduct"
        label="Available training courses"
        class="product__form__select"
        :options="availableProductOptions"
        @change="onUpdateProductOptions"
      />
      <CallToAction
        value="Submit"
        class="product__form__cta"
        data-test-id="product__form-cta---submit"
        :is-loading="isAddingProduct"
        @click="onAddProduct"
      />
      <CallToAction
        class="product__form__cta"
        data-test-id="product__form-cta---close"
        value="Cancel"
        :theme="themes.GREY"
        @click="onCancel"
      />
    </form>
    <InputErrorMessage
      :error="productErrorMessage"
      class="product__validation-error-message"
    />
    <ProductList
      :product-list="platformProductList"
      :error-message="errorMessage"
      :isLoading="isLoadingProductList"
      :feedback-message="feedbackMessage"
    />
  </section>
</template>

<script>
import ProductList from "@/organisms/ProductList/ProductList";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import {
  customErrorMessages,
  actionName,
  mutationName,
  defaultIcons,
  themes,
  getterName,
  operations
} from "@/constants";
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";

export default {
  name: "ManageProduct",
  components: {
    ProductList,
    ProfileHeader,
    MultiSelect,
    CallToAction,
    InputErrorMessage
  },
  data() {
    return {
      errorMessage: "",
      feedbackMessage: "",
      productErrorMessage: "",
      isAddNewProductVisible: false,
      selectedProducts: [],
      availableProductOptions: [],
      isAddingProduct: false,
      themes
    };
  },
  created() {
    this.fetchProductListWrapper();
    this.fetchAvailableProductList();
    this.updateAvailableProductOptions();
  },
  beforeUnmount() {
    this.resetProduct();
  },
  computed: {
    ...mapState({
      platformProductList: (state) => state.product.platformProductList,
      isLoadingProductList: (state) => state.product.isLoadingProductList,
      client: (state) => state.onboardingClient.client
    }),
    ...mapGetters({
      getUniqueAvailableProductsOptions:
        getterName.PRODUCT.GET_UNIQUE_AVAILABLE_PRODUCTS_OPTIONS
    }),
    CTAOptions() {
      return {
        ...defaultIcons.EDIT,
        value: "Add Training course",
        icon: null
      };
    }
  },
  methods: {
    ...mapActions({
      fetchPlatformProductList: actionName.PRODUCT.FETCH_PLATFORM_PRODUCT_LIST,
      fetchAvailableProductList:
        actionName.PRODUCT.FETCH_AVAILABLE_PRODUCT_LIST,
      assignAvailableProductToPlatform:
        actionName.PRODUCT.ASSIGN_AVAILABLE_PRODUCT_TO_PLATFORM
    }),
    ...mapMutations({
      resetProduct: mutationName.PRODUCT.RESET_PRODUCT
    }),
    async fetchProductListWrapper() {
      try {
        this.errorMessage = "";
        this.feedbackMessage = (await this.fetchPlatformProductList()) || "";
      } catch {
        this.errorMessage = customErrorMessages.training.fetchTrainingError;
      }
    },
    onUpdateProductOptions({ eventType, value }) {
      if (eventType === operations.ADD) {
        this.selectedProducts.push(value);
      } else if (eventType === operations.DELETE) {
        this.selectedProducts = this.selectedProducts.filter(
          (product) => product !== value
        );
      }
      this.updateAvailableProductOptions();
    },
    updateAvailableProductOptions() {
      this.availableProductOptions = makeOptionsForMultiSelect(
        this.selectedProducts,
        this.selectedProducts,
        this.getUniqueAvailableProductsOptions
      );
    },
    async onAddProduct() {
      if (this.selectedProducts.length) {
        this.isAddingProduct = true;
        this.productErrorMessage = "";
        try {
          await this.assignAvailableProductToPlatform({
            courseIds: this.selectedProducts
          });
          this.isAddNewProductVisible = false;
        } catch {
          this.productErrorMessage =
            customErrorMessages.generic.addDataError("product");
        } finally {
          this.isAddingProduct = false;
        }
      } else {
        this.productErrorMessage = customErrorMessages.generic.required;
      }
    },
    onCancel() {
      this.selectedProducts = [];
      this.updateAvailableProductOptions();
      this.productErrorMessage = "";
      this.feedbackMessage = "";
      this.isAddNewProductVisible = !this.isAddNewProductVisible;
    }
  }
};
</script>
<style lang="scss" scoped>
.product {
  &__form {
    display: flex;
    justify-content: right;
    &__select {
      width: 100%;
    }
    &__cta {
      margin-left: $spacing12;
      height: fit-content;
      align-self: flex-end;
    }
  }
  &__validation-error-message {
    text-align: left;
  }
}
</style>
