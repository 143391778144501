<template>
  <div>
    <AppLoader v-if="isLoadingLocal" class="text-center" small />
    <b-container fluid class="m-0" v-if="!isLoadingLocal">
      <b-row v-if="showingIntro && taskStatus != 4 && form.isShowFormIntro">
        <b-col cols="8" offset="2">
          <div
            :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
            class="align-middle my-5"
          >
            <h3>
              {{
                form.formTitle
                  ? form.formTitle[selectedLanguage || "english"]
                  : "No Title"
              }}
            </h3>
            <div
              style="
                height: calc(100vh - 275px);
                overflow: hidden auto;
                text-align: left;
              "
            >
              <p
                v-html="
                  form.formIntroText
                    ? form.formIntroText[selectedLanguage || 'english']
                    : 'No Title'
                "
              ></p>
            </div>

            <Checkbox
              class="text-center my-3"
              v-model="userConsent"
              :label="$t('questionnaires.consent')"
            />
            <b-button
              variant="outline-danger"
              class="mr-2"
              @click="redirectToDashboard"
              >{{ $t("questionnaires.backToDashboard") }}</b-button
            >
            <b-button
              variant="outline-success"
              :disabled="!userConsent"
              class="mr-2"
              @click="onClickProceed()"
              >{{ $t("questionnaires.proceed") }}</b-button
            >
          </div>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col :cols="taskStatus != 4 ? 9 : 6" style="overflow: hidden">
          <b-row :dir="$store.state.rightAlign ? 'rtl' : 'ltr'">
            <b-col class="text-center">
              <h3 class="mt-2">
                {{
                  form.formTitle
                    ? form.formTitle[selectedLanguage || "english"]
                    : "No Title"
                }}
              </h3>
              <p>
                {{
                  form.formDescription
                    ? form.formDescription[selectedLanguage || "english"]
                    : "No Description"
                }}
              </p>
            </b-col>
          </b-row>

          <div id="scrollspy" class="preview-scrollable-wrapper p-3">
            <div
              v-if="form.formSection.length"
              id="element"
              style="background-color: #f4f4f4"
            >
              <b-form v-if="!isLoading">
                <SectionContainer
                  v-for="(sectionData, index) in form.formSection"
                  :id="'section_' + index"
                  :isDisabled="isDisabled"
                  :isPreview="true"
                  :selectedLanguage="selectedLanguage"
                  :sectionData="sectionData"
                  :inputType="sectionData.inputType"
                  :key="index"
                  :counter="index"
                />
              </b-form>
            </div>
          </div>
          <b-row class="mt-3">
            <b-col cols="6" class="text-left">
              <a
                v-if="form.formSection.length"
                href="#"
                class="btn btn-warning"
                v-scroll-to="{
                  el: '#scrollspy',
                  container: '#scrollspy',
                  duration: 300,
                  easing: 'linear',

                  force: true,

                  x: false,
                  y: true
                }"
              >
                <font-awesome-icon icon="angle-up" />
              </a>
            </b-col>
            <b-col v-if="$route.name === routeNames[routeKeys.USER_FORM_PAGE]">
              <!-- v-if="form.formSection.length && taskStatus !=4" -->

              <b-button-group class="float-right">
                <b-button
                  v-if="form.formSection.length && taskStatus != 4"
                  variant="outline-primary"
                  @click="onSavePostData"
                  :disabled="isLoading || isLoadingSaveProgress"
                >
                  <span v-if="isLoading || isLoadingSaveProgress">
                    <b-spinner small type="grow"></b-spinner>
                    {{ $t("savingProgressText") }}
                  </span>
                  <span v-else>{{ $t("saveProgressText") }}</span>
                </b-button>
                <b-button
                  :disabled="isLoadingSaveProgress"
                  v-if="form.formSection.length && taskStatus != 4"
                  variant="outline-success"
                  @click="onValidateSubmit"
                  >{{ $t("submitText") }}</b-button
                >
              </b-button-group>
            </b-col>
          </b-row>
        </b-col>

        <!-- end of col-9 preview area righ sections list -->
        <b-col
          cols="3"
          class="mt-2"
          style="
            position: relative;
            overflow-y: auto;
            height: calc(100vh - 200px);
          "
        >
          <b-list-group>
            <b-list-group-item
              :class="rightAlign ? 'text-right' : 'text-left'"
              v-for="(section, index) in form.formSection"
              :key="section.id"
              :active="currentSectionIndex === index"
              @click="onScrollClick(index)"
              v-show="
                section.advance &&
                !section.advance.hideSectionTitle &&
                !section.isHidden
              "
              button
            >
              <b-badge v-if="!rightAlign" variant="dark">{{
                index + 1
              }}</b-badge>
              &nbsp; {{ section.sectionTitle[selectedLanguage] }}
              <b-badge
                v-if="rightAlign && selectedLanguage == 'arabic'"
                variant="dark"
                >{{
                  new Intl.NumberFormat("ar-EG", {}).format(index + 1)
                }}</b-badge
              >
              <b-badge
                v-if="rightAlign && selectedLanguage == 'hebrew'"
                variant="dark"
                >{{ index + 1 }}</b-badge
              >
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>

      <!-- review modal -->
      <b-modal
        :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
        :content-class="$store.state.rightAlign ? 'text-right' : 'text-left'"
        id="modal-1"
        modal-header-close="No"
        :ok-title="$t('submitText')"
        :cancel-title="$t('cancelBtnText')"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        :title="$t('questionnaires.submitModalTitle')"
        @ok="onSubmit"
      >
        <p>{{ $t("formSubmitingMessage") }}</p>
        <p></p>
      </b-modal>

      <b-modal
        :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
        id="modal-2"
        :content-class="$store.state.rightAlign ? 'text-right' : 'text-left'"
        modal-header-close="No"
        ok-only
        :ok-title="$t('closeBtnText')"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        :title="$t('questionnaires.formSubmittedModalTitle')"
        @ok="redirectToDashboard"
      >
        <p>{{ $t("formSubmittedMessage") }}</p>
        <p></p>
      </b-modal>

      <b-alert
        :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
        :content-class="$store.state.rightAlign ? 'text-right' : 'text-left'"
        v-model="showErrorToast"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000"
        variant="danger"
        dismissible
        solid
        >{{ $t("formValidationMessage") }}</b-alert
      >

      <b-modal
        id="modal-leave"
        modal-header-close="No"
        :ok-title="$t('closeBtnText')"
        :cancel-title="$t('cancelBtnText')"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        :title="$t('toast.title')"
        @ok="leaveConfirm = true"
      >
        <p>{{ $t("formLeaveAlertMessage") }}</p>
        <p></p>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { Helper } from "@/helpers";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import SectionContainer from "@/containers/SectionUserContainer";
import AppLoader from "@/components/Loader/Loader";
import { EventBus } from "@/event-bus.js";
import { makeAuthorizationHeader, HTTP } from "@/utils";
import _ from "lodash";
import { getRiskScores } from "@/utils/formRiskScoring/formRiskScoring.utils";
import {
  getterName,
  routeKeys,
  routeNames,
  urls,
  languages,
  languagesInitial,
  localStorageItem,
  actionName,
  languageLocaleKeys
} from "@/constants";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "UserFormPage",
  components: {
    SectionContainer,
    AppLoader,
    Checkbox
  },
  data() {
    return {
      isDisabled: 0,
      enabled: true,
      dragging: false,
      selected: "english",
      isInvalid: false,
      pageIntroText: "",
      showingIntro: true,
      showErrorToast: false,
      currentSectionIndex: 0,
      taskStatus: null,
      userConsent: 0,
      taskId: null,
      taskFiles: [],
      fromButton: false,
      isLoadingLocal: false,
      isLoadingSaveProgress: false,
      inValidElements: [],
      inValidSections: [],
      leaveConfirm: false,
      initialResults: [],
      riskData: {
        percentage: null,
        risk: null,
        rating: null,
        color: null
      },
      individualSectionData: [],
      sectionTotalScore: 0,
      totalScoreAllSections: null,
      highestScoreSection: null,
      riskScoringData: {
        percentage: null,
        risk: null,
        rating: null,
        color: null
      },
      totalFormScore: 0,
      maxFoundValueOpt: 0,
      checkRiskRating: null,
      isDefaultCalculation: false,
      defaultHighRiskElements: [],
      routeNames,
      routeKeys
    };
  },
  created() {
    if (this.$route.params.taskId) {
      this.taskId = this.$route.params.taskId;
    }
    this.$store.state.validFields = true;
    if (!this.taskId) {
      this.$router.replace(urls.THIRD_PARTY_ONBOARDING_QUESTIONNAIRES);
      return false;
    }
    this.getTaskForm();
  },
  beforeRouteLeave(_, __, next) {
    if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
      if (this.fromButton) {
        next();
      } else {
        const answer = window.confirm(this.$t("formLeaveAlertMessage"));
        if (answer) {
          if (!this.isUserThirdParty) {
            this.setFormLanguage(languagesInitial.ENGLISH);
          }
          this.$store.state.userFormSubmitArray = [];
          this.$store.state.getUserFormSubmitArray = [];
          this.onResetLocalData();
          next();
        } else {
          next(false);
        }
      }
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage",
      isLoading: "isLoading",
      fullName: getterName.USER.GET_FULL_NAME,
      isUserThirdParty: getterName.USER.IS_USER_THIRD_PARTY
    }),
    ...mapState({
      userFormSubmitArray: "userFormSubmitArray",
      form: "form1",
      validFields: "validFields",
      rightAlign: "rightAlign",
      progressValue: "progressValue",
      progressMaxValue: "progressMaxValue",
      userId: (state) => state.user.userId,
      onboardingTask: (state) => state.onboardingTasks.onboardingTask
    })
  },
  watch: {
    selectedLanguage(lang) {
      this.updateTitles(lang);
    }
  },
  methods: {
    ...mapActions({
      setFormDefaultLanguage:
        actionName.NO_NAME_SPACE.SET_FORM_DEFAULT_LANGUAGE,
      setLanguage: actionName.NO_NAME_SPACE.SET_LANGUAGE,
      fetchOnboardingTask: actionName.ONBOARDING_TASKS.FETCH_ONBOARDING_TASK
    }),
    ...mapMutations(["TOGGLE_LOADER"]),
    ...mapMutations(["UPDATE_FORM"]),

    makeToast() {
      const h = this.$createElement;
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        this.$t("toast.generatePDF"),
        h("b-spinner", { props: { type: "grow", small: true } })
      ]);
      this.$bvToast.toast([vNodesMsg], {
        variant: "warning",
        title: "PDF",
        autoHideDelay: 5000
      });
    },
    onClickProceed() {
      this.setUserConsent();
      this.setUserFormSchemaToStore();
    },
    onResetLocalData() {
      this.removeLocalStorageItems();
      this.$store.state.registerData = {};
      this.$store.state.progressValue = 0;
      this.$store.state.progressMaxValue = 0;
    },
    async setUserConsent() {
      try {
        await HTTP(
          "post",
          `v1/mytpp/task/consent/${this.taskId}`,
          {
            taskStatus: this.taskStatus == 0 ? 1 : this.taskStatus,
            userConsent: this.userConsent
          },
          makeAuthorizationHeader(),
          null,
          true
        );
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
      this.showingIntro = false;
    },
    isCombinedHighRisk(element) {
      return !!(
        element.combinedHighRiskAnswerValues?.length &&
        element.combinedHighRiskQuestionsAndAnswers?.[0]?.question &&
        element.combinedHighRiskQuestionsAndAnswers?.[0]?.answers?.length
      );
    },
    initializeFormData() {
      let formDefaultLanguage = localStorage.getItem(
        localStorageItem.FORM_DEFAULT_LANGUAGE
      );
      this.$store.state.progressMaxValue = 0;
      if (
        this.$store.state.getUserFormSubmitArray &&
        this.$store.state.getUserFormSubmitArray.length
      ) {
        this.$store.state.userFormSubmitArray =
          this.$store.state.getUserFormSubmitArray;
        this.$store.state.progressMaxValue =
          this.$store.state.getUserFormSubmitArray.length;
      } else {
        this.$store.state.userFormSubmitArray = [];
        let tempForm = _.cloneDeep(this.form);
        tempForm.formSection.map((section) => {
          section.formElements.map((element, elementIndex) => {
            this.$store.state.progressMaxValue += 1;
            this.$store.state.userFormSubmitArray.push({
              elements: [],
              sectionId: section.id,
              sectionIndex: section.index,
              elementIndex,
              elementId: element.id,
              elementInputType: element.inputType,
              isDefaultHighRisk:
                element && element.isDefaultHighRisk
                  ? element.isDefaultHighRisk
                  : 0,
              isCombinedHighRisk: this.isCombinedHighRisk(element),
              selectedProductCountry:
                element && element.selectedProductCountry
                  ? element.selectedProductCountry
                  : "",
              highRiskProducts:
                element && element.highRiskProducts
                  ? element.highRiskProducts
                  : [],
              elementTitle: element.label,
              elementTitleAbb: element.abbreviationLabelText || element.label,
              question_abb_table: element.labelForTable || null,
              sectionTitle: section.sectionTitle,
              language: formDefaultLanguage,
              isMultiSelect: element.configuration
                ? element.configuration.isMultipleSelect
                  ? 1
                  : 0
                : 0,
              weightage: element.weightage || null,
              dataAttributes: element.dataAttributes || null,
              selectedChart: element.selectedCharts || null,
              isWorkFlowNa: element.isNa && element.isNa == 1 ? 1 : 0,
              isHyperLink: element.isHyperLink ? 1 : 0,
              hyperLinkUrl: element.hyperLinkUrl || null,
              hyperLinkText: element.hyperLinkText || null,
              hyperLinkTextBefore: element.hyperLinkTextBefore || null,
              hyperLinkTextAfter: element.hyperLinkTextAfter || null,
              isHiddenElement: element.isHidden ? 1 : 0,
              isHiddenSection: section.isHidden && section.isHidden ? 1 : 0,
              enableFilterForManageThirdParty:
                (element &&
                  parseInt(element.enableFilterForManageThirdParty)) ||
                0,
              enableSelfReportingForManageThirdParty:
                (element &&
                  parseInt(element.enableSelfReportingForManageThirdParty)) ||
                0,
              enableSelfReportingForThirdPartyProfile:
                (element &&
                  parseInt(element.enableSelfReportingForThirdPartyProfile)) ||
                0
            });
          });
        });
      }
    },
    updateTitles(lang) {
      const dd = this.$store.state.userFormSubmitArray;
      dd.map((a) => {
        a.language = lang;
        return a;
      });
    },
    changeLanguage() {
      let tt = this.selected.toLowerCase();
      this.setLanguage(tt);
      this.TOGGLE_LOADER(100);
    },
    setUserFormSchemaToStore() {
      let form = JSON.parse(this.onboardingTask.form.schema);
      this.updateFormToStore(form);
    },
    updateFormToStore(form) {
      this.UPDATE_FORM(form);
    },
    onValidateSubmit() {
      EventBus.emit("onSubmitEndUserForm", "form submit");
      this.getInvalidFields();
      this.checkForInValidFields();
    },
    checkForInValidFields() {
      let isValid = false;
      outerLoop: for (
        let i = 0;
        i < this.$store.state.form1.formSection.length;
        i++
      ) {
        for (
          let x = 0;
          x < this.$store.state.form1.formSection[i].formElements.length;
          x++
        ) {
          if (
            this.$store.state.form1.formSection[i].formElements[x].isInValid
          ) {
            let inValidData = {
              elementId:
                this.$store.state.form1.formSection[i].formElements[x].id,
              sectionId: this.$store.state.form1.formSection[i].id
            };
            EventBus.emit("OnInvalidFieldCatch", inValidData);
            this.showErrorToast = true;
            isValid = false;

            break outerLoop;
          }
          isValid = true;
        }
      }
      if (isValid) {
        this.onReviewSubmit();
      }
    },
    getInvalidFields() {
      this.inValidElements = [];
      this.inValidSections = [];
      for (let i = 0; i < this.$store.state.form1.formSection.length; i++) {
        for (
          let x = 0;
          x < this.$store.state.form1.formSection[i].formElements.length;
          x++
        ) {
          if (
            this.$store.state.form1.formSection[i].formElements[x].isInValid
          ) {
            this.inValidElements.push(
              this.$store.state.form1.formSection[i].formElements[x]
            );
          }
        }
      }
    },
    onReviewSubmit() {
      if (this.$store.state.validFields) {
        this.showErrorToast = false;
        this.$bvModal.show("modal-1");
      } else {
        this.showErrorToast = true;
      }
    },
    async onSubmit() {
      if (!this.form?.suppressScoreReporting) {
        if (
          this.form.formTitle[this.selectedLanguage]
            .toLowerCase()
            .includes("lieferkettengesetz") ||
          this.form.formTitle[this.selectedLanguage]
            .toLowerCase()
            .includes("human rights questionnaire")
        ) {
          const { totalRisk, topicDataObjectList } = getRiskScores({
            form: this.$store.state.userFormSubmitArray,
            formId: "supplyChainDDQ",
            formSchema: this.form.formSection
          });

          this.riskData = totalRisk;
          this.initialResults = topicDataObjectList;
        } else {
          if (
            this.form.formTitle[this.selectedLanguage] ==
            "Tetratech Supplier Screening Safeguard"
          ) {
            this.calulateScoringForTetraTech();
          } else {
            // msq v2 scoring and v3 scoring
            this.calculateHighestScoreForCategory();
          }
          // overall new scoring pseudo

          this.riskData = {
            ...this.riskData,
            data: this.initialResults
          };
        }
      }

      await this.saveFormData(4);
    },

    // FIRST WE NEED TO LOOP THROUGH EVERY INDIVIDUAL SECTION
    // THEN INSIDE EACH SECTION WE WILL LOOP THROUGH EACH FORM ELEMENT
    // FETCH THE SELECTED ITEM SCORE AND ADD THEM ACCORDING TO SECTIONS.
    // FINAL ARRAY OF SECTIONS SCORES IS ARRAY OF OBJ
    // EACH OBJ WILL HAVE SECTION NAME ,
    // INDEX AND SUM OF SCORES OF ALL ITS ELEMENTS
    calulateScoringForTetraTech() {
      this.setSectionsRiskRatingData();
      this.getTotalScoreFromAllSections();
      this.getTotalScoreOfAllSelectedOptions();
      this.calculateRiskRatingForSections();
    },
    // for setting each section risk
    setSectionsRiskRatingData() {
      this.$store.state.form1.formSection.map((section, index) => {
        section.formElements.map((element) => {
          if (element && element.selected) {
            this.sectionTotalScore += parseInt(element.selected.score);
          }
          // setting up individual section data
          this.individualSectionData[index] = {
            sectionTitle: section.sectionTitle.english,
            index: section.index,
            score: this.sectionTotalScore
          };
        });
        this.sectionTotalScore = 0;
      });
      this.individualSectionData.shift();
      this.individualSectionData.pop();

      this.individualSectionData.map((section, index) => {
        this.individualSectionData[index] = {
          risk: this.getRisk(section.score),
          color: this.getColor(section.score),
          sectionTitle: section.sectionTitle,
          index: section.index,
          score: section.score
        };
      });
      // final sending section data
      this.initialResults = this.individualSectionData;
    },
    getTotalScoreFromAllSections() {
      let tempScoreMax = 0;
      this.$store.state.form1.formSection.map((section) => {
        section.formElements.map((element) => {
          if (element && element.options) {
            let value = Math.max(...element.options.map((opt) => opt.score));
            tempScoreMax += parseInt(value);
          }
        });
      });
      this.totalFormScore = tempScoreMax;
    },
    // TO CALCULATE THE SUM OF ALL SECTIONS SCORE
    getTotalScoreOfAllSelectedOptions() {
      let totalScoreAllSections = 0;

      this.individualSectionData.map((item) => {
        totalScoreAllSections += item.score;
      });

      this.totalScoreAllSections = totalScoreAllSections;
    },
    // LOOP SECTIONS AND HIGHEST SCORE WALA OBJECT GET HOGA
    // ADD SWITCH CASE FOR THE ACCORDING TO RATINGS CONDITIONS
    calculateRiskRatingForSections() {
      // get highest score section
      let maximumScoreValue = Math.max.apply(
        Math,
        this.individualSectionData.map((item) => item.score)
      );
      let highestScoreSection = this.individualSectionData.find(
        (item) => item.score == maximumScoreValue
      );
      let score = highestScoreSection.score;

      // get DDQ risk score percent
      let riskScorePercent = this.totalScoreAllSections / this.totalFormScore;
      this.riskData = {
        percentage: riskScorePercent,
        risk: this.getRisk(score),
        color: this.getColor(score),
        rating: this.checkRiskRating
      };
    },
    // to get color according to score
    getColor(score) {
      switch (true) {
        case score >= 0 && score <= 2:
          this.checkRiskRating = 1;
          return "Green";
        case score > 2 && score <= 4:
          this.checkRiskRating = 5;
          return "Yellow";
        case score > 4:
          this.checkRiskRating = 10;
          return "Red";
        default:
          return "Invalid";
      }
    },
    // to get risk according to score
    getRisk(score) {
      switch (true) {
        case score >= 0 && score <= 2:
          return "Low";
        case score > 2 && score <= 4:
          return "Moderate";
        case score > 4:
          return "High";
        default:
          return "Invalid";
      }
    },
    checkExistanceCountry() {
      let sections = this.$store.state.form1.formSection;
      let sec4score = null;

      let compareSectionSix = sections.find(
        (sec) => sec.sectionTitle.english == "Details of Services/Goods"
      );
      let sectionFour = sections.find(
        (sec) => sec.sectionTitle.english == "Business Operations"
      );
      let foundSectionFourEle = null;
      if (sectionFour) {
        foundSectionFourEle = this.$store.state.userFormSubmitArray.find(
          (ele) => ele.elementId == sectionFour.formElements[0].id
        );
      }

      if (compareSectionSix) {
        let found = compareSectionSix.formElements.find(
          (a) => a.inputType == "countries"
        );

        if (found) {
          let foundSectionSixEle = this.$store.state.userFormSubmitArray.find(
            (ele1) => ele1.elementId == found.id
          );

          if (foundSectionFourEle) {
            sec4score = foundSectionFourEle.elements.find((ele) => ele);
          }

          if (foundSectionSixEle && foundSectionSixEle.elements.length) {
            foundSectionSixEle.elements.find((ele) => {
              if (
                foundSectionFourEle.isHiddenElement == 0 &&
                ele.score == 13 &&
                sec4score
              ) {
                ele.score = 0;
                sec4score.score = 13;
              } else if (
                foundSectionFourEle.isHiddenElement == 0 &&
                ele.score &&
                sec4score &&
                sec4score.score == 0
              ) {
                sec4score.score = 0;
              } else {
                ele.score = 0;
              }
            });
          } else {
            sec4score.score = Math.max(
              ...Array.from(
                sec4score.postValue,
                (selectedValue) => selectedValue.riskScore
              )
            );
          }

          let highestScore = found.dataAttributes.find(
            (atr) => atr.dataAttributeKey == "highest_score"
          );
          highestScore.dataAttributeValue = 0;
        }
      }
    },

    calculateHighestScoreForCategory() {
      let topics = [];
      this.checkExistanceCountry();
      this.$store.state.form1.formSection.map((section) => {
        section.formElements.map((element) => {
          if (element.dataAttributes) {
            let topic = element.dataAttributes.find(
              (ele) => ele.dataAttributeKey == "topic"
            );
            let highestScore = element.dataAttributes.find(
              (ele) => ele.dataAttributeKey == "highest_score"
            );
            //  to check maybe we need to remove or apply v3 condition
            if (highestScore && highestScore.dataAttributeValue != 0) {
              let hh = parseFloat(highestScore.dataAttributeValue) || 0;

              topics.push({
                topic: (topic && topic.dataAttributeValue) || null,
                weightage: parseFloat(element.weightage),
                actualScore: 0,
                highestScore1: hh
              });
            }
          }
        });
      });

      const newTopicsArr = [];
      const newTopicsIdArr = [];
      for (const topic of topics) {
        const index = newTopicsIdArr.indexOf(topic.topic);
        if (index >= 0) {
          newTopicsArr[index].actualScore += topic.actualScore;
          newTopicsArr[index].highestScore1 += topic.highestScore1;
        } else {
          newTopicsIdArr.push(topic.topic);
          newTopicsArr.push(topic);
        }
      }
      this.calculateRiskScore(newTopicsArr);
    },

    calculateRiskScore(highestScoress) {
      this.initialResults = [];
      let topics = [];
      let initialResults = [];
      this.$store.state.userFormSubmitArray.map((element) => {
        // fetch topic and high score //
        if (element.dataAttributes) {
          let foundScore = element.elements.find((ele) => ele);
          let topic = element.dataAttributes.find(
            (ele) => ele.dataAttributeKey == "topic"
          );
          let highestScore = element.dataAttributes.find(
            (ele) => ele.dataAttributeKey == "highest_score"
          );

          if (
            foundScore &&
            highestScore &&
            highestScore.dataAttributeValue != 0
          ) {
            let hh = parseFloat(highestScore.dataAttributeValue) || 0;
            let ss = (foundScore.score && parseFloat(foundScore.score)) || 0;
            // setting score in topics
            topics.push({
              topic: (topic && topic.dataAttributeValue) || null,
              weightage: parseInt(element.weightage),
              actualScore: ss,
              highestScore1: hh
            });
          }
        }
      });
      let newTopicsArr = [];
      const newTopicsIdArr = [];
      for (const topic of topics) {
        const index = newTopicsIdArr.indexOf(topic.topic);
        if (index >= 0) {
          newTopicsArr[index].actualScore += topic.actualScore;
        } else {
          newTopicsIdArr.push(topic.topic);
          newTopicsArr.push(topic);
        }
      }

      newTopicsArr.map((a) => {
        let y = highestScoress.find((t) => t.topic == a.topic);
        {
          a.highestScore1 = y.highestScore1;
        }
      });

      if (
        this.form.formTitle.english == "Modern Slavery Questionnaire v 3" ||
        this.form.formTitle.english == "Modern Slavery Questionnaire v 4"
      ) {
        newTopicsArr =
          this.checkIfActualScoreGreaterThanHighestScore(newTopicsArr);
      }

      initialResults = newTopicsArr.map((ele) => {
        // formula for final score calculations
        let finalScoreForTopic =
          (ele.actualScore / ele.highestScore1) * ele.weightage;
        // to calculate according to catgories
        if (
          this.form.formTitle.english == "Modern Slavery Questionnaire v 3" ||
          this.form.formTitle.english == "Modern Slavery Questionnaire v 4"
        ) {
          this.analyseTopicRiskDataForMsqV3(
            ele.actualScore,
            ele.topic,
            finalScoreForTopic
          );
        } else {
          this.analyseTopicRiskData(
            ele.actualScore,
            ele.topic,
            finalScoreForTopic
          );
        }
        return {
          ...ele,
          finalScoreForTopic,
          data: this.riskData
        };
      });

      this.initialResults = initialResults;
      let sum = initialResults.reduce(
        (accum, item) => accum + item.finalScoreForTopic,
        0
      );
      if (
        this.form.formTitle.english == "Modern Slavery Questionnaire v 3" ||
        this.form.formTitle.english == "Modern Slavery Questionnaire v 4"
      ) {
        this.analizeMsqV3RiskData(sum);
      } else {
        this.analyseRiskData(sum);
      }
    },
    checkIfActualScoreGreaterThanHighestScore(newTopicsArr) {
      newTopicsArr.map((item) => {
        if (item.actualScore > item.highestScore1) {
          item.actualScore = item.highestScore1;
        }
      });
      return newTopicsArr;
    },
    analizeMsqV3RiskData(sum) {
      let highRiskElements = [];
      let matchElements;
      this.form.formSection.map((section) => {
        section.formElements.map((element) => {
          if (
            element.isDefaultHighRisk &&
            element.isDefaultHighRisk == 1 &&
            element.highRiskAnsValue.length
          ) {
            matchElements = {
              defaultHighEleValue: element.highRiskAnsValue[0],
              label: element.label
            };
            highRiskElements.push(matchElements);
          }
        });
      });
      if (highRiskElements && highRiskElements.length) {
        loop: for (let ele of this.$store.state.userFormSubmitArray) {
          if (ele.isDefaultHighRisk && ele.isDefaultHighRisk == 1) {
            if (
              ele.elements &&
              ele.elements.length &&
              ele.elements[0].postValue
            ) {
              for (let item of highRiskElements) {
                if (ele.elementTitle.english == item.label.english) {
                  if (
                    ele.elements[0].postValue.value ==
                    item.defaultHighEleValue.value
                  ) {
                    this.isDefaultCalculation = false;
                    this.riskData = {
                      percentage: sum,
                      risk: "High",
                      color: "Red",
                      rating: "10"
                    };
                    break loop;
                  } else {
                    this.isDefaultCalculation = true;
                  }
                }
              }
            }
          }
        }
      }

      if (this.isDefaultCalculation) {
        this.analyseRiskData(sum);
      }
    },

    // for calculating individual element
    analyseRiskData(sum) {
      switch (true) {
        // initial code for msq v2 commented what happens if sum = 0 so the riskdata falls into default switch case which in invalid
        // case sum > 0 && sum < 5:
        // sum >= 0 is added thats why
        case sum >= 0 && sum < 5:
          this.riskData = {
            percentage: sum,
            risk: "Low",
            color: "Green",
            rating: "1"
          };
          break;
        case sum >= 5 && sum < 15:
          this.riskData = {
            percentage: sum,
            risk: "Low",
            color: "Green",
            rating: "2"
          };
          break;

        case sum >= 15 && sum < 25:
          this.riskData = {
            percentage: sum,
            risk: "Low",
            color: "Green",
            rating: "3"
          };
          break;

        case sum >= 25 && sum < 35:
          this.riskData = {
            percentage: sum,
            risk: "Low",
            color: "Green",
            rating: "4"
          };
          break;

        case sum >= 35 && sum < 45:
          this.riskData = {
            percentage: sum,
            risk: "Moderate",
            color: "Yellow",
            rating: "5"
          };
          break;
        case sum >= 45 && sum < 55:
          this.riskData = {
            percentage: sum,
            risk: "Moderate",
            color: "Yellow",
            rating: "6"
          };
          break;
        case sum >= 55 && sum < 65:
          this.riskData = {
            percentage: sum,
            risk: "Moderate",
            color: "Yellow",
            rating: "7"
          };
          break;
        case sum >= 65 && sum < 75:
          this.riskData = {
            percentage: sum,
            risk: "High",
            color: "Red",
            rating: "8"
          };
          break;
        case sum >= 75 && sum < 85:
          this.riskData = {
            percentage: sum,
            risk: "High",
            color: "Red",
            rating: "9"
          };
          break;
        case sum >= 85 && sum <= 100:
          this.riskData = {
            percentage: sum,
            risk: "High",
            color: "Red",
            rating: "10"
          };
          break;

        default:
          this.riskData = {
            percentage: sum,
            risk: "Invalid",
            rating: "Invalid",
            color: "Invalid"
          };
      }
    },
    // calculation accordingly to categories msqv3
    analyseTopicRiskDataForMsqV3(sum, topic, percentage) {
      switch (topic) {
        case "jurisdiction":
          {
            if (sum > 12 && sum <= 47) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 4 && sum <= 12) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 4) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "activity":
          {
            if (sum > 15 && sum <= 48) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 5 && sum <= 15) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 5) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "workforce":
          {
            if (sum > 14 && sum <= 47) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum >= 0 && sum <= 14) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "policies_and_systems":
          {
            if (sum > 10 && sum <= 24) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 6 && sum <= 10) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 6) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "human_rights":
          {
            if (sum > 10 && sum <= 18) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 4 && sum <= 10) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 4) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
      }
    },
    // calculation accordingly to categories
    analyseTopicRiskData(sum, topic, percentage) {
      switch (topic) {
        case "jurisdiction":
          {
            if (sum > 12 && sum <= 18) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 4 && sum <= 12) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 4) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "activity":
          {
            if (sum > 15 && sum <= 32) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 5 && sum <= 15) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 5) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "workforce":
          {
            if (sum > 14) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum >= 0 && sum <= 14) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "policies_and_systems":
          {
            if (sum > 10 && sum <= 13) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 6 && sum <= 10) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 6) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
        case "human_rights":
          {
            if (sum > 10 && sum <= 13) {
              this.riskData = {
                percentage,
                risk: "High",
                color: "Red",
                score: sum
              };
            } else if (sum > 4 && sum <= 10) {
              this.riskData = {
                percentage,
                risk: "Moderate",
                color: "Yellow",
                score: sum
              };
            } else if (sum >= 0 && sum <= 4) {
              this.riskData = {
                percentage,
                risk: "Low",
                color: "Green",
                score: sum
              };
            } else {
              this.riskData = {
                percentage,
                risk: "Invalid",
                color: "Invalid",
                score: sum
              };
            }
          }
          break;
      }
    },

    async saveFormData(status) {
      let formDefaultLanguage = localStorage.getItem(
        localStorageItem.FORM_DEFAULT_LANGUAGE
      );
      let taskData = this.$store.state.userFormSubmitArray;
      try {
        let result = await HTTP(
          "post",
          "v1/mytpp/task/submitform",
          {
            userId: this.$route.params.userId || this.userId,
            userName: this.fullName,
            taskData,
            status,
            taskId: this.taskId,
            riskData: {
              ...this.riskData,
              data: this.initialResults
            },
            formDefaultLanguage
          },
          makeAuthorizationHeader(),
          null,
          true
        );
        if (result) {
          this.fromButton = true;
          this.$bvModal.show("modal-2");
        }
      } catch {
        this.fromButton = true;
        this.$bvModal.show("modal-2");
      }
    },

    onScrollClick(index) {
      let id = `section_${index}`;
      this.currentSectionIndex = index;
      let element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },

    // auto save logic for user form--------
    async onSavePostData() {
      EventBus.emit("onSubmitEndUserForm", "progress");
      this.isLoadingSaveProgress = true;

      let taskData = this.$store.state.userFormSubmitArray;
      try {
        await HTTP(
          "post",
          `v1/mytpp/task/save/${this.taskId}`,
          {
            taskData,
            taskStatus: this.taskStatus == 0 ? 1 : this.taskStatus,
            userName: this.fullName
          },
          makeAuthorizationHeader(),
          null,
          false
        );
        this.isLoadingSaveProgress = false;
        Helper.makeToast(
          this.$bvToast,
          "success",
          this.$t("toast.saveSuccess"),
          this.$t("toast.title")
        );
      } catch {
        this.isLoadingLocal = false;
        Helper.makeToast(this.$bvToast, "danger", this.$t("toast.saveError"));
      }
    },

    checkForNewUser(data) {
      let array = data;
      let aa = array.find((ele) => ele.elements);
      if (aa) {
        return true;
      } else {
        return null;
      }
    },

    async getTaskForm() {
      this.isLoadingLocal = true;
      const payload = {
        taskId: this.taskId,
        userId: this.$route.params.userId || this.userId,
        taskType: 1
      };
      await this.fetchOnboardingTask(payload);

      if (this.onboardingTask && this.onboardingTask.form) {
        if (this.onboardingTask.language) {
          this.setFormLanguage(this.onboardingTask.language);
        }
        let form = this.onboardingTask.form.schema;
        let form_data = JSON.parse(this.onboardingTask.formData);

        if (form_data && form_data.hasOwnProperty("user_data")) {
          let found = this.checkForNewUser(form_data.user_data);

          if (found) {
            this.$store.state.getUserFormSubmitArray = form_data.user_data;
          } else {
            this.$store.state.getUserFormSubmitArray = [];
          }
        }
        if (
          form_data &&
          form_data.hasOwnProperty("user_consent") &&
          form_data.user_consent == 1
        ) {
          this.showingIntro = false;
        } else {
          this.showingIntro = true;
        }
        localStorage.setItem(
          localStorageItem.ACTIVE_TASK_STATUS,
          this.onboardingTask.taskStatus
        );
        this.setUserFormSchemaToStore();
        this.initializeFormData();
        let ff = JSON.parse(form);
        this.taskStatus = this.onboardingTask.taskStatus;
        this.isDisabled = this.taskStatus == 4 ? 1 : 0;
        if (ff.isShowFormIntro) {
          this.pageIntroText = ff.formIntroText[this.selectedLanguage];
        }

        this.isLoadingLocal = false;
      } else {
        this.redirectToDashboard();
      }
    },

    redirectToDashboard() {
      if (this.isUserThirdParty) {
        this.$router.replace(urls.THIRD_PARTY_ONBOARDING_QUESTIONNAIRES);
      } else if (this.$route.query?.tp) {
        this.$router.replace(urls.THIRD_PARTY_PROFILE(this.$route.query?.tp));
      } else {
        this.$router.replace(urls.MANAGE_THIRD_PARTY);
      }
    },

    setFormLanguage(taskLanguageCode) {
      const existingLanguage =
        languages[taskLanguageCode] || languages[languagesInitial.ENGLISH];

      localStorage.setItem(
        localStorageItem.FORM_DEFAULT_LANGUAGE,
        existingLanguage
      );
      this.$i18n.locale =
        languageLocaleKeys[taskLanguageCode] ||
        languages[taskLanguageCode] ||
        languages[languagesInitial.ENGLISH];
      this.setLanguage(existingLanguage);
      this.setFormDefaultLanguage(existingLanguage);
    },

    removeLocalStorageItems() {
      localStorage.removeItem(localStorageItem.TASK_ID);
      localStorage.removeItem(localStorageItem.ACTIVE_FORM_ID);
      localStorage.removeItem(localStorageItem.ACTIVE_FORM_SCHEMA);
      localStorage.removeItem(localStorageItem.TASK_FORM_DATA);
      localStorage.removeItem(localStorageItem.ACTIVE_TASK_STATUS);
      localStorage.removeItem(localStorageItem.TEMP_FORM);
      localStorage.removeItem(localStorageItem.USER_CONSENT);
    }
  }
};
</script>
<style scoped>
.preview-scrollable-wrapper {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 240px);
}
.selectPicket {
  width: 350px;
}
</style>
