<template id="template-personal-details">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <!-- input content -->

    <b-collapse class="card mt-5" id="collapse-1" v-model="collapse">
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1'"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div class="card-body text-left">
        <h4>{{ fieldData.label[selectedLanguage] }}</h4>
        <p>{{ fieldData.description[selectedLanguage] }}</p>
        <div class="form-row">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.titleDisabled"
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="fieldData.selectedTitleHelperText[selectedLanguage]"
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireSelectedTitle)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.selectedTitleLabel[selectedLanguage] }}
              </label>

              <v-select
                :disabled="isDisabled == 1"
                :options="fieldData.optionsTitle"
                :class="
                  v$.form.selectedTitle.$error
                    ? 'border border-danger rounded'
                    : null
                "
                label="text"
                code="value"
                v-model="v$.form.selectedTitle.$model"
                :state="
                  v$.form && v$.form.selectedTitle.$dirty
                    ? !v$.form.selectedTitle.$error
                    : null
                "
                @update:modelValue="checkFormProgress"
                @blur="v$.form.$touch()"
              />
              <label
                v-if="
                  v$.form.selectedTitle.$error &&
                  fieldData.validation.isRequireSelectedTitle &&
                  !form.selectedTitle
                "
                class="text-danger"
                style="font-size: 80%"
                >{{
                  fieldData.selectedTitleValidationMessage[selectedLanguage]
                }}</label
              >
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.foreNameDisabled"
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="fieldData.foreNameHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.foreNameValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireForeName)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.foreNameLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.foreName.$model"
                :state="
                  v$.form && v$.form.foreName.$dirty
                    ? !v$.form.foreName.$error
                    : null
                "
                :placeholder="fieldData.foreNamePlaceholder[selectedLanguage]"
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="form-row">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.middelNameDisabled"
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="fieldData.middelNamesHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.middelNamesValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireMiddelNames)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.middelNamesLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.middelNames.$model"
                :state="
                  v$.form && v$.form.middelNames.$dirty
                    ? !v$.form.middelNames.$error
                    : null
                "
                :placeholder="
                  fieldData.middelNamesPlaceholder[selectedLanguage]
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.lastNameDisabled"
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="fieldData.lastNameHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.lastNameValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireLastName)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.lastNameLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.lastName.$model"
                :state="
                  v$.form && v$.form.lastName.$dirty
                    ? !v$.form.lastName.$error
                    : null
                "
                :placeholder="fieldData.lastNamePlaceholder[selectedLanguage]"
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="form-row">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.knownAsDisabled"
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="fieldData.knownAsHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.knownAsValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireKnownAs)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.knownAsLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.knownAs.$model"
                :state="
                  v$.form && v$.form.knownAs.$dirty
                    ? !v$.form.knownAs.$error
                    : null
                "
                :placeholder="fieldData.knownAsPlaceholder[selectedLanguage]"
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance || !fieldData.advance.dateOfBirthDisabled
            "
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="fieldData.dateOfBirthHelperText[selectedLanguage]"
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireDateOfBirth)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.dateOfBirthLabel[selectedLanguage] }}
              </label>
              <b-input-group
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              >
                <Datepicker
                  :disabled="isDisabled == 1"
                  input-class="custom-date-picker"
                  calendar-class="date-calender"
                  class="form-control"
                  :class="
                    v$.form.dateOfBirth.$error
                      ? 'border border-danger rounded'
                      : null
                  "
                  v-model="v$.form.dateOfBirth.$model"
                  :disabled-dates="disabledDates"
                  :format="format_date"
                  :state="
                    v$.form && v$.form.dateOfBirth.$dirty
                      ? !v$.form.dateOfBirth.$error
                      : null
                  "
                  @input="checkFormProgress"
                  @blur="v$.form.$touch()"
                ></Datepicker>
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </b-input-group>
              <label
                v-if="
                  v$.form.dateOfBirth.$error &&
                  fieldData.validation.isRequireDateOfBirth &&
                  !form.dateOfBirth
                "
                class="text-danger"
                style="font-size: 80%"
                >{{
                  fieldData.dateOfBirthValidationMessage[selectedLanguage]
                }}</label
              >
            </b-form-group>
          </div>
        </div>
        <div class="form-row">
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance || !fieldData.advance.maritalStatusDisabled
            "
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="
                fieldData.selectedMaritalStatusHelperText[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(
                      fieldData.validation.isRequireSelectedMaritalStatus
                    )
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.selectedMaritalStatusLabel[selectedLanguage] }}
              </label>
              <b-form-radio-group
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                :options="maritalStatusOptions"
                v-model="v$.form.selectedMaritalStatus.$model"
                :state="
                  v$.form && v$.form.selectedMaritalStatus.$dirty
                    ? !v$.form.selectedMaritalStatus.$error
                    : null
                "
              ></b-form-radio-group>
              <label
                v-if="
                  v$.form.selectedMaritalStatus.$error &&
                  fieldData.validation.isRequireSelectedMaritalStatus &&
                  !form.selectedMaritalStatus
                "
                class="text-danger"
                style="font-size: 80%"
                >{{
                  fieldData.selectedMaritalStatusValidationMessage[
                    selectedLanguage
                  ]
                }}</label
              >
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance || !fieldData.advance.contactNumberDisabled
            "
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="
                fieldData.mobileContactNumberHelperText[selectedLanguage]
              "
              :invalid-feedback="
                fieldData.mobileContactNumberValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(
                      fieldData.validation.isRequireMobileContactNumber
                    )
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.mobileContactNumberLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                onkeydown="return event.keyCode !== 69"
                :disabled="isDisabled == 1"
                type="number"
                v-model="v$.form.mobileContactNumber.$model"
                :state="
                  v$.form && v$.form.mobileContactNumber.$dirty
                    ? !v$.form.mobileContactNumber.$error
                    : null
                "
                :placeholder="
                  fieldData.mobileContactNumberPlaceholder[selectedLanguage]
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
                onpaste="return false;"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="form-row">
          <!-- :invalid-feedback="fieldData.personalEmailAddressValidationMessage[selectedLanguage]" -->
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance || !fieldData.advance.emailAddressDisabled
            "
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="
                fieldData.personalEmailAddressHelperText[selectedLanguage]
              "
              :invalid-feedback="
                !v$.form.personalEmailAddress.email
                  ? $t('validations.invalidFormat')
                  : fieldData.personalEmailAddressValidationMessage[
                      selectedLanguage
                    ]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(
                      fieldData.validation.isRequirePersonalEmailAddress
                    )
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.personalEmailAddressLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="email"
                v-model="v$.form.personalEmailAddress.$model"
                :state="
                  v$.form && v$.form.personalEmailAddress.$dirty
                    ? !v$.form.personalEmailAddress.$error
                    : null
                "
                :placeholder="
                  fieldData.personalEmailAddressPlaceholder[selectedLanguage]
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="
              !fieldData.advance || !fieldData.advance.linkedInProfileDisabled
            "
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="
                fieldData.linkedInProfileHelperText[selectedLanguage]
              "
              :invalid-feedback="
                fieldData.linkedInProfileValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireLinkedInProfile)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.linkedInProfileLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.linkedInProfile.$model"
                :state="
                  v$.form && v$.form.linkedInProfile.$dirty
                    ? !v$.form.linkedInProfile.$error
                    : null
                "
                :placeholder="
                  fieldData.linkedInProfilePlaceholder[selectedLanguage]
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="form-row" v-if="showFreeForm">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.freeFormDisabled"
          >
            <b-form-group
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :description="fieldData.freeFormHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.freeFormValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireFreeForm)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.freeFormLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.freeForm.$model"
                :state="
                  v$.form && v$.form.freeForm.$dirty
                    ? !v$.form.freeForm.$error
                    : null
                "
                :placeholder="fieldData.freeFormPlaceholder[selectedLanguage]"
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-collapse>

    <!-- show advance field options -->
    <b-modal
      cancel-title="Close"
      ok-title="save"
      @ok="onSaveData"
      size="xl"
      v-model="showSettings"
      scrollable
      title="Advanced Settings"
    >
      <AdvanceSettings
        :fieldData="fieldData"
        v-on:toggle-field="showSettings = !showSettings"
        :sectionIndex="sectionIndex"
        :fieldIndex="fieldIndex"
      />
    </b-modal>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import AdvanceSettings from "../workflowSettings/advancePersonalDetailsSettings.vue";
import { Helper } from "@/helpers";
import { useVuelidate } from "@vuelidate/core";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { required } from "@vuelidate/validators";
import moment from "moment";
import "moment/locale/es";
import Datepicker from "vuejs-datepicker";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "PersonalDetailsGroup",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    MenuButtons,
    AdvanceSettings,
    vSelect,
    Datepicker
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"])
  },
  data() {
    return {
      showFreeForm: false,
      maritalStatusOptions: [],
      isHidden: false,
      condition: {},
      title: null,
      label: null,
      showSettings: false,
      collapse: true,
      isUpdtating: false,
      selectedRowIndex: null,
      form: {
        selectedTitle: null,
        foreName: null,
        middelNames: null,
        lastName: null,
        knownAs: null,
        dateOfBirth: null,
        selectedMaritalStatus: null,
        mobileContactNumber: null,
        personalEmailAddress: null,
        linkedInProfile: null,
        freeForm: null
      },
      disabledDates: {
        to:
          this.fieldData.advanceDate &&
          this.fieldData.advanceDate.disabledMinDateInput
            ? new Date(Date.now() - 864e5)
            : this.fieldData.advanceDate && this.fieldData.advanceDate.minDate
            ? new Date(this.fieldData.advanceDate.minDate)
            : "", // Disable all dates up to specific date
        from:
          this.fieldData.advanceDate &&
          this.fieldData.advanceDate.disabledMaxDateInput
            ? new Date()
            : this.fieldData.advanceDate && this.fieldData.advanceDate.maxDate
            ? new Date(this.fieldData.advanceDate.maxDate)
            : "" // Disable all dates after specific date
      }
    };
  },

  mounted() {
    this.setFreeFormFields();
    this.maritalStatusOptions = this.fieldData.optionsMaritalStatus;
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name == "MyCompanyView"
    ) {
      this.getDataForUser();
    }
  },

  validations() {
    return {
      form: {
        selectedTitle: {
          [this.selectedTitle]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireSelectedTitle
              ? required
              : !required
        },
        foreName: {
          [this.foreName]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireForeName
              ? required
              : !required
        },
        middelNames: {
          [this.middelNames]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireMiddelNames
              ? required
              : !required
        },
        lastName: {
          [this.lastName]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireLastName
              ? required
              : !required
        },
        knownAs: {
          [this.knownAs]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireKnownAs
              ? required
              : !required
        },
        dateOfBirth: {
          [this.dateOfBirth]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireDateOfBirth
              ? required
              : !required
        },
        selectedMaritalStatus: {
          [this.selectedMaritalStatus]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireSelectedMaritalStatus
              ? required
              : !required
        },
        mobileContactNumber: {
          [this.mobileContactNumber]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireMobileContactNumber
              ? required
              : !required
        },
        personalEmailAddress: {
          [this.personalEmailAddress]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequirePersonalEmailAddress
              ? required
              : !required
        },
        linkedInProfile: {
          [this.linkedInProfile]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireLinkedInProfile
              ? required
              : !required
        },
        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireFreeForm
              ? required
              : !required
        }
      }
    };
  },

  methods: {
    setFreeFormFields() {
      Object.assign(this.fieldData, {
        freeFormLabel: {
          english: "Free Form"
        },
        freeFormPlaceholder: {
          english: ""
        },
        freeFormHelperText: {
          english: ""
        },
        freeFormValidationMessage: {
          english: "This field is required"
        }
      });
      let validation = {
        ...this.fieldData.validation,
        isRequireFreeForm: this.fieldData.validation.isRequireFreeForm || false
      };
      Object.assign(this.fieldData, { validation });
      let advance = {
        ...this.fieldData.advance,
        freeFormDisabled: this.fieldData.advance.freeFormDisabled || true
      };
      Object.assign(this.fieldData, { advance });
      this.showFreeForm = true;
    },

    format_date(value) {
      let customFormat = this.fieldData.format;
      if (customFormat == "default") {
        return moment(value).format("DD-MM-YYYY");
      } else {
        return moment(value).locale("en").format(customFormat);
      }
    },

    setDataToUser() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements = this.postData;
          }
        });
      }
    },

    getDataForUser() {
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elements && data.elements.length > 0) {
          data.elements.map((element) => {
            if (data.elementId === this.fieldData.id) {
              (this.form.selectedTitle =
                element[element.findIndex((x) => x.key == "title")].postValue),
                (this.form.foreName =
                  element[
                    element.findIndex((x) => x.key == "firstName")
                  ].postValue),
                (this.form.middelNames =
                  element[
                    element.findIndex((x) => x.key == "middelName")
                  ].postValue),
                (this.form.lastName =
                  element[
                    element.findIndex((x) => x.key == "lastName")
                  ].postValue),
                (this.form.knownAs =
                  element[
                    element.findIndex((x) => x.key == "knownAs")
                  ].postValue),
                (this.form.dateOfBirth =
                  element[
                    element.findIndex((x) => x.key == "dateOfBirth")
                  ].postValue),
                (this.form.selectedMaritalStatus =
                  element[
                    element.findIndex((x) => x.key == "maritalStatus")
                  ].postValue),
                (this.form.mobileContactNumber =
                  element[
                    element.findIndex((x) => x.key == "contactNumber")
                  ].postValue),
                (this.form.personalEmailAddress =
                  element[
                    element.findIndex((x) => x.key == "emailAddress")
                  ].postValue),
                (this.form.linkedInProfile =
                  element[
                    element.findIndex((x) => x.key == "linkedInProfile")
                  ].postValue),
                (this.form.freeForm =
                  element[
                    element.findIndex((x) => x.key == "freeForm")
                  ].postValue);
            }
          });
        }
      });
    },

    setValidation() {
      let validation = {
        isRequireSelectedTitle: true,
        isRequireForeName: true,
        isRequireMiddelNames: true,
        isRequireLastName: true,
        isRequireKnownAs: true,
        isRequireDateOfBirth: true,
        isRequireSelectedMaritalStatus: true,
        isRequireMobileContactNumber: true,
        isRequirePersonalEmailAddress: true,
        isRequireLinkedInProfile: true
      };
      Object.assign(this.fieldData, { validation });
    },

    stTempData() {
      this.postData = [];
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        let aa = [
          {
            postValue: this.form.selectedTitle,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.titlePostName) ||
              "titlePostName",
            key: "title",
            label: this.fieldData.selectedTitleLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.foreName,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.foreNamePostName) ||
              "foreNamePostName",
            key: "firstName",
            label: this.fieldData.foreNameLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.middelNames,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.middelNamePostName) ||
              "middelNamePostName",
            key: "middelName",
            label: this.fieldData.middelNamesLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.lastName,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.lastNamePostName) ||
              "lastNamePostName",
            key: "lastName",
            label: this.fieldData.lastNameLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.knownAs,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.knownAsPostName) ||
              "knownAsPostName",
            key: "knownAs",
            label: this.fieldData.knownAsLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.dateOfBirth,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.dateOfBirthPostName) ||
              "dateOfBirthPostName",
            key: "dateOfBirth",
            label: this.fieldData.dateOfBirthLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.selectedMaritalStatus,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.maritalStatusPostName) ||
              "maritalStatusPostName",
            key: "maritalStatus",
            label:
              this.fieldData.selectedMaritalStatusLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.mobileContactNumber,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.contactNumberPostName) ||
              "contactNumberPostName",
            key: "contactNumber",
            label:
              this.fieldData.mobileContactNumberLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.personalEmailAddress,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.emailAddressPostName) ||
              "emailAddressPostName",
            key: "emailAddress",
            label:
              this.fieldData.personalEmailAddressLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.linkedInProfile,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.linkedInProfilePostName) ||
              "linkedInProfilePostName",
            key: "linkedInProfile",
            label: this.fieldData.linkedInProfileLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.freeForm,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.freeFormPostName) ||
              "freeFormPostName",
            key: "freeForm",
            label: this.fieldData.freeFormLabel[this.selectedLanguage]
          }
        ];
        this.postData.push(aa);
        this.setDataToUser();
      }
    },

    checkFormProgress() {
      const registerData = clone(this.$store.state.registerData);

      if (this.v$.form.$errors?.length && this.v$.form.$invalid) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;
      } else {
        registerData[this.fieldData.id] = "personal-details";
        this.$store.state.registerData = registerData;
      }
      const numkeys = Object.keys(registerData).length;
      // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
      this.$store.state.progressValue = numkeys;
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    }
  },
  created() {
    EventBus.on("onSubmitEndUserForm", () => {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length && this.v$.form.$invalid) {
        this.$store.state.validFields = false;
        this.fieldData.isInValid = true;
        return false;
      } else {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;

        this.stTempData();
      }
    });
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.v-select-toggle[data-v-138dff1d] {
  border: 1px solid $light-silver;
  background-color: $white;
  height: 40px;
}
.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $bright-gray !important;
  border: 1px solid $light-gray !important;
  height: 40px;
}
.custom-date-picker {
  width: 100%;
  border: none;
}
.custom-date-picker:focus {
  border: none;
}
.date-calender {
  z-index: 999 !important;
  width: 350px !important;
}
</style>
