const validationKeys = {
  titles: {
    INCORRECT_FILE_TYPE: "incorrectFileType",
    ERROR: "error",
    VALIDATION_ERROR: "validationError",
    SYSTEM_ERROR: "systemError"
  },
  REQUIRED: "required",
  NUMBER: "number",
  MIN_LENGTH: "minLength",
  MAX_LENGTH: "maxLength",
  MAX_LENGTH_255: "maxLength255",
  IS_VALID_URL: "isValidUrl",
  IS_VALID_MAIL_TO_LINK: "isValidMailToLink",
  IS_VALID_LINK: "isValidLink",
  IS_VALID_EMAIL: "isValidEmail",
  IS_UNIQUE_IN_LIST: "isUniqueInList",
  ALPHA_NUMERIC: "alphaNumeric",
  DUPLICATE_EMAIL: "duplicateEmail",
  ADD_LEARNER_ERROR: "addLearnerError",
  FETCH_TRAINING_ERROR: "fetchTrainingError",
  INVALID_QUERY_FILTER: "invalidQueryFilter",
  INCORRECT_FILE_TYPE_ONLY_XLSX: "incorrectFileTypeOnlyAllowXLSX",
  EXCEL_VALIDATION_ERROR: "excelValidationError",
  ONLY_UPLOAD_ONE_FILE: "onlyUploadOneFile",
  UNEXPECTED_ERROR: "unexpectedError",
  ADD_DATA_ERROR: "addDataError"
};

const errorLinkMessages = {
  [validationKeys.REQUIRED]: "Please enter a link.",
  [validationKeys.IS_VALID_URL]:
    "Link must start with mailto:, https:// or http://.",
  [validationKeys.IS_VALID_MAIL_TO_LINK]:
    "Please enter a valid email starting with mailto:",
  [validationKeys.IS_VALID_LINK]: "Please enter a valid link."
};

const errorMessages = {
  companyName: {
    [validationKeys.REQUIRED]: "Please enter the legal company name."
  },
  companyAddress: {
    [validationKeys.REQUIRED]: "Please enter the company address."
  },
  legalDisclaimer: {
    [validationKeys.REQUIRED]: "Please enter the legal disclaimer."
  },
  copyrightNotice: {
    [validationKeys.REQUIRED]: "Please enter the copyright notice link."
  },
  companyNameAbbreviation: {
    [validationKeys.REQUIRED]:
      "Please enter the legal company name abbreviation."
  },
  releaseNoteLink: {
    ...errorLinkMessages
  },
  privacyCookieLink: {
    ...errorLinkMessages
  },
  faqLink: {
    ...errorLinkMessages
  },
  supportEmailLink: {
    ...errorLinkMessages
  },
  requestSupportLink: {
    ...errorLinkMessages
  },
  tncLink: {
    ...errorLinkMessages
  },
  companyWebsite: {
    ...errorLinkMessages,
    [validationKeys.REQUIRED]: "Please enter the company website link."
  },
  knowledgeCenterLink: {
    ...errorLinkMessages
  },
  attributeName: {
    [validationKeys.REQUIRED]: "Attribute name is required."
  },
  attributeDataType: {
    [validationKeys.REQUIRED]: "Attribute type is required."
  },
  attributeSubtype: {
    [validationKeys.REQUIRED]: "Attribute subtype is required."
  },
  username: {
    [validationKeys.REQUIRED]: "Enter your username."
  },
  password: {
    [validationKeys.REQUIRED]: "Enter your password."
  },
  forgotPasswordEmail: {
    [validationKeys.REQUIRED]: "Please enter an email address.",
    [validationKeys.IS_VALID_EMAIL]: "Please enter a valid email address."
  },
  productId: {
    [validationKeys.REQUIRED]: "Please select any item from list."
  },
  actionName: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]:
      "The action name must not exceed 255 characters in length."
  },
  actionDescription: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]:
      "The action description must not exceed 255 characters in length."
  },
  expectedVariableName: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.IS_UNIQUE_IN_LIST]: "Name has to be unique."
  },
  expectedVariableType: {
    [validationKeys.REQUIRED]: "Required"
  },
  variableName: {
    [validationKeys.REQUIRED]: "Required"
  },
  valueDataType: {
    [validationKeys.REQUIRED]: "Required"
  },
  value: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.NUMBER]: "Requires a number."
  },
  valueType: {
    [validationKeys.REQUIRED]: "Required"
  },
  functionStepsList: {
    [validationKeys.MIN_LENGTH]: "The function body is required."
  },
  nextActionStep: {
    [validationKeys.REQUIRED]: "Required"
  }
};

const customErrorMessages = {
  actions: {
    variableName: {
      [validationKeys.IS_UNIQUE_IN_LIST]: "The variable name is already in use."
    },
    value: {
      [validationKeys.NUMBER]: "Require a number."
    },
    functionStepsList: {
      [validationKeys.MIN_LENGTH]: "At least 1 step is required."
    }
  },
  training: {
    [validationKeys.DUPLICATE_EMAIL]:
      "A learner with the provided email is already registered on the course, please try a new one.",
    [validationKeys.ADD_LEARNER_ERROR]:
      "An error occurred while adding a learner, please try again.",
    [validationKeys.FETCH_TRAINING_ERROR]:
      "Failed to retrieve the available training list."
  },
  tasks: {
    [validationKeys.INVALID_QUERY_FILTER]: (filter) =>
      `Removed invalid filter '${filter}'.`
  },
  generic: {
    titles: {
      [validationKeys.titles.INCORRECT_FILE_TYPE]: "Error: Incorrect File Type",
      [validationKeys.titles.ERROR]: "Error",
      [validationKeys.titles.VALIDATION_ERROR]: "Validation Error",
      [validationKeys.titles.SYSTEM_ERROR]: "System Error"
    },
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]: (value) => `Maximum of ${value} characters.`,
    [validationKeys.UNEXPECTED_ERROR]:
      "We’ve encountered an unexpected error. Please try again later. If the issue persists, contact support for assistance.",
    [validationKeys.ADD_DATA_ERROR]: (value) =>
      `An error occurred while adding a ${value}, Please contact support for further assistance.`
  },
  uploadingFiles: {
    [validationKeys.EXCEL_VALIDATION_ERROR]:
      "We've encountered one or more issues with the Excel file that prevent it from being processed correctly. Please go through the list of validation errors below, correct them in the Excel file, and then re-upload it. If you need assistance with this, please contact the Support team.",
    [validationKeys.INCORRECT_FILE_TYPE_ONLY_XLSX]:
      "Only .xlsx files are allowed. Please upload an Excel file in the .xlsx format and try again. If you need assistance, please contact Support.",
    [validationKeys.ONLY_UPLOAD_ONE_FILE]: "Please only upload one file."
  }
};

export {
  customErrorMessages,
  errorMessages,
  errorLinkMessages,
  validationKeys
};
